<template>
  <div id="order-list">
    <van-nav-bar
      :title="title"
      left-arrow
      @click-left="() => $router.back()"
    >
      <template #right v-if="$route.params && $route.params.status === 'unpickup'">
        <router-link :to="{ name: 'pickup-order' }">
          <van-button type="primary" size="small" round>
            {{ $t('pickup_order.pickup' )}}
          </van-button>
        </router-link>
      </template>
    </van-nav-bar>
    <van-search
      v-model="search_parcel_code"
      :placeholder="$t('order.input_parcel_code_to_search')"
      show-action
      :action-text="$t('common.cancel')"
      @search="onLoad"
      @cancel="onCancelSearch"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.not_more')"
        @load="onLoad"
      >
        <van-cell-group
          class="order-cell"
          v-for="order in list"
          :key="order.id"
        >
          <van-cell :to="{ name: 'order-detail', params: { id: order.id }}" is-link>
            <ul class="order-info">
              <li>
                <span class="label">{{ $t('order.type') }}</span>
                <span>{{ $t(`order.types.${order.type}`) }}</span>
              </li>
              <li>
                <span class="label">{{ $t('order.parcel_code') }}</span>
                <span>{{ order.parcel_code }}</span>
                <span class="ml-3" v-if="order.parcel_model">{{ order.parcel_model.name }}</span>
              </li>
              <li v-if="order.issue" class="text-danger">
                <van-icon name="warning" />
                {{ order.issue }}
              </li>
              <li v-if="order.tracking_no">
                <span class="label">{{ $t('order.tracking_no') }}</span>
                {{ order.tracking_no }}
              </li>
              <li class="mb-3">
                <div class="row">
                  <div class="col text-center">
                    <h3>{{ order.from_district }}</h3>
                    <div>{{ order.sender_address.name }}</div>
                  </div>
                  <div class="col text-center">
                    <div class="mt-3">
                      <van-icon name="cross" size="50" class="text-danger" v-if="~[11, 12, 13].indexOf(order.status)" />
                      <van-icon name="success" size="50" class="text-success" v-else-if="order.status === 5" />
                      <img src="@/assets/icon/arrow-right-double.png" width="50" v-else />
                    </div>
                  </div>
                  <div class="col text-center">
                    <h3>{{ order.to_district }}</h3>
                    <div>{{ order.receiver_address.name }}</div>
                  </div>
                </div>
              </li>
              <li>
                <span class="label">{{ $t('order.status') }}</span>
                <span :class="`order-status-${order.status_str}`">
                  {{ $t(`order_status.${order.status_str}`) }}
                </span>
              </li>
              <li v-if="~[2, 3, 4].indexOf(order.status) && order.tracks.length">
                <van-notice-bar left-icon="logistics" :text="order.tracks[0].message" />
              </li>
              <li>
                <span class="label">{{ $t('order.created_at') }}</span>
                {{ order.created_at }}
              </li>
              <li>
                <span class="label">{{ $t('order.price') }}</span>
                {{ order.discount_price | currency }}
              </li>
              <li v-if="order.duty_paid">
                <span class="text-danger">{{ $t('DDP.tips') }}</span>
                <ol class="text-danger">
                  <li>{{ $t('DDP.step1') }}</li>
                  <li>{{ $t('DDP.step2') }}</li>
                </ol>
                <div class="text-center">
                  <van-button
                    plain
                    hairline
                    type="primary"
                    size="small"
                    class="mr-2"
                    url="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5NzU1NDQwMg=="
                  >
                    {{ $t('DDP.wechat') }}
                  </van-button>
                  <van-button
                    plain
                    hairline
                    type="info"
                    size="small"
                    url="http://ghzx.gdems.com/ghzx/common/index.jsp"
                  >
                    {{ $t('DDP.web') }}
                  </van-button>
                </div>
              </li>
              <li v-if="order.additional_fees.length">
                <span class="label">{{ $t('order.additional_fee') }}</span>
                {{ $t('order.additional_fee_unit', { num: order.additional_fees.length }) }}
              </li>
              <li  v-if="order.unpaid_additional_fees_count">
                <span class="text-warning">
                  <van-icon name="warning" />
                  {{ $t('order.additional_fee_unpaid_tips', { num: order.unpaid_additional_fees_count }) }}
                </span>
              </li>
            </ul>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  NavBar, Search, PullRefresh, List, NoticeBar,
} from 'vant';

Vue.use(NavBar);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(NoticeBar);

export default {
  name: 'OrderList',
  data() {
    return {
      list: [],
      search_parcel_code: '',
      loading: false,
      refreshing: false,
      finished: false,
      pagination: null,
      page: 1,
    };
  },
  computed: {
    title() {
      if (this.$route.params.status) {
        return this.$t(`order.statuses.${this.$route.params.status}`);
      }
      return this.$t('order_list.title');
    },
  },
  watch: {
    '$route.params.status': {
      handler() {
        this.onRefresh();
      },
    },
  },
  methods: {
    onRefresh() {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onCancelSearch() {
      this.list = [];
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      const params = { status: this.$route.params.status };
      if (this.search_parcel_code) {
        this.list = [];
        this.page = 1;
        params.code = this.search_parcel_code;
      } else {
        params.page = this.page;
      }
      this.$api.order.get(params).then(({ data: response }) => {
        this.pagination = response.meta.pagination;
        if (this.page < this.pagination.total_pages) {
          this.page++;
        }
        this.list = [...this.list, ...response.data];
        if (this.list.length === this.pagination.total) {
          this.finished = true;
        }
      }).catch(() => {
        this.finished = true;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss">
  @import "../../style/variables";
  #order-list {
    .order-cell {
      margin: .8rem;
      border-radius: .8rem;
      .van-cell {
        border-radius: .8rem;
      }
      .order-info {
        .label {
          color: $gray-6;
          margin-right: .2rem;
          &:after {
            content: ":";
          }
        }
      }
    }
  }
</style>

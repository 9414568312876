<template>
  <div id="order-index">
    <van-nav-bar
      :title="$t('app.order_manage')"
      left-arrow
      @click-left="() => $router.back()"
    />

    <van-notice-bar
        v-if="unpaidAdditionalFeeCount"
        mode="link"
        @click="$router.push({ name: 'orders', params: { status: 'additional' } })"
        :text="$t('order.additional_fee_unpaid_order_tips', { num: unpaidAdditionalFeeCount })"
    />
<!--    <van-divider>{{ $t('order.my_order') }}</van-divider>-->

    <van-grid class="mt-3 p-3" :column-num="2">
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.all')"
        :to="{ name: 'orders' }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.unpaid')"
        :to="{ name: 'orders', params: { status: 'unpaid' } }"
        :badge="unpaidOrderCount || ''"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.unpickup')"
        :to="{ name: 'orders', params: { status: 'unpickup' } }"
        :badge="unpickupOrderCount || ''"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.pickup')"
        :to="{ name: 'orders', params: { status: 'pickup' } }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.tracking')"
        :to="{ name: 'orders', params: { status: 'tracking' } }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.completion')"
        :to="{ name: 'orders', params: { status: 'completion' } }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.close')"
        :to="{ name: 'orders', params: { status: 'close' } }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('order.statuses.issue')"
        :to="{ name: 'orders', params: { status: 'issue' }}"
        :badge="issueOrderCount || ''"
      />
    </van-grid>

    <van-divider>{{ $t('pickup_order.pickup') }}</van-divider>

    <van-grid class="mt-3 p-3" :column-num="2">
      <van-grid-item
        icon="orders-o"
        :text="$t('pickup_order.order')"
        :to="{ name: 'pickup-order' }"
      />
      <van-grid-item
        icon="orders-o"
        :text="$t('pickup_order.order_list')"
        :to="{ name: 'pickup-orders' }"
      />
    </van-grid>

  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  NavBar, Grid, GridItem,
} from 'vant';

Vue.use(NavBar);
Vue.use(Grid);
Vue.use(GridItem);

export default {
  name: 'OrderIndex',
  mounted() {

  },
  computed: {
    ...mapGetters(['unpaidOrderCount', 'unpickupOrderCount', 'issueOrderCount', 'unpaidAdditionalFeeCount']),
  },
};
</script>

<style lang="scss">
  #order-index {
    .van-grid{
      &.van-hairline--top {
        &:after {
          border-top-width: 0;
        }
      }
    }
  }
</style>

<template>
  <div id="price-v2">
    <van-nav-bar
      :title="$t('order.title')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-steps :active="step">
      <van-step>{{ $t('order.step_0') }}</van-step>
      <van-step>{{ $t('order.step_1') }}</van-step>
      <van-step>{{ $t('order.step_2') }}</van-step>
    </van-steps>

    <van-form v-show="step === 0">
      <!-- 選擇地區 -->
      <van-field
        required
        readonly
        clickable
        name="picker"
        :value="districtText"
        :label="$t('order.target_district')"
        :placeholder="`${$t('address.placeholders.area')}`"
        @click="showDistrictPicker = true"
      />
      <!-- 包裹類型 -->
      <van-field
        :label="$t('order.parcel_type')"
        required
      >
        <template #input>
          <van-radio-group v-model="order.base.type" direction="horizontal">
            <van-radio name="spx">{{ $t('order.parcel_type_spx') }}</van-radio>
            <van-radio name="doc">{{ $t('order.parcel_type_doc') }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :label="$t('order.long')"
        v-model.number="order.base.long"
        type="number"
        required
      >
        <template #extra>CM</template>
      </van-field>
      <van-field
        :label="$t('order.width')"
        v-model.number="order.base.width"
        type="number"
        required
      >
        <template #extra>CM</template>
      </van-field>
      <van-field
        :label="$t('order.height')"
        v-model.number="order.base.height"
        type="number"
        required
      >
        <template #extra>CM</template>
      </van-field>
      <van-field :label="$t('order.weight')" required>
        <template #input>
          <van-stepper v-model="order.base.weight" input-width="6rem" :min="0.1" :max="weightLimited" theme="round" :step="0.5" />
        </template>
        <template #extra>
          KG
        </template>
      </van-field>
      <!-- 预设 -->
      <van-radio-group v-if="order.base.type === 'spx'" v-model="preset">
        <van-cell-group title="预设">
          <van-cell-group>
            <van-cell
              v-for="pm in parcelSizes"
              :key="pm.key"
              :name="pm.key"
              clickable
              @click="selectedPresetSize(pm)"
            >
              <template #title>
                {{ pm.long }}(L) &times; {{ pm.width }}(W) &times; {{ pm.height }}(H)
                <span class="weight-label">{{ pm.weight }}KG</span>
              </template>
              <template #right-icon>
                <van-radio :name="pm.key" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-cell-group>
      </van-radio-group>
    </van-form>
    <van-form ref="order_detail" v-show="step === 1">
      <van-cell-group :title="$t('order.base_info')">
        <!-- 寄件方式 -->
        <van-field
          :label="$t('order.type')"
          required
        >
          <template #input>
            <van-radio-group v-model="order.detail.type" direction="horizontal" @change="handleChangeType">
              <van-radio :name="1">{{ $t('order.types.1') }}</van-radio>
              <van-radio :name="2">{{ $t('order.types.2') }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- 门店 -->
        <van-field
          v-if="order.detail.type === 1"
          v-model="order.detail.post_office_code"
          :label="$t('order.post_office_code')"
          :placeholder="$t('order.placeholders.parcel_code_or_post_office_code')"
          @blur="handlePostOfficeCodeBlur"
          :required="true"
          maxlength="30"
        >
          <template #button>
            <van-button @click="scanPostOfficeCode" size="small" icon="scan"></van-button>
          </template>
        </van-field>
        <van-cell
          v-if="post_office"
          :title="$t('order.post_office')"
          :value="post_office.name"
        ></van-cell>
        <!-- 预计投寄日期 -->
        <van-field
          v-if="order.detail.type === 1"
          :label="$t('order.expected_post_date')"
          readonly
          clickable
          :value="order.detail.expected_post_date"
          @click="showExpectedPostDatePicker = true"
          required
        />
        <van-popup v-model="showExpectedPostDatePicker" position="bottom" round>
          <van-datetime-picker
            v-model="expectedPostDate.value"
            type="month-day"
            :title="$t('order.expected_post_date_popup_title')"
            :min-date="expectedPostDate.min"
            :max-date="expectedPostDate.max"
            @confirm="handleConfirmExpectedPostDate"
          />
        </van-popup>
        <!-- 寄件人 -->
        <van-field
          type="textarea"
          :rows="1"
          :label="$t('order.sender')"
          :placeholder="$t('common.click_for_select_field', { field: $t('order.sender')})"
          readonly
          clickable
          :autosize="true"
          :value="sender"
          @click="showSenderPicker = true"
          :required="true"
        />
        <van-popup v-model="showSenderPicker" position="bottom" round>
          <address-list
            type="sender"
            @click-item="handleClickSenderAddressItem"
            @deleted="handleDeleteSenderAddressitem"
          />
        </van-popup>
        <!-- 收件人 -->
        <van-field
          type="textarea"
          :rows="1"
          :label="$t('order.receiver')"
          :placeholder="$t('common.click_for_select_field', { field: $t('order.receiver')})"
          readonly
          clickable
          :autosize="true"
          :value="receiver"
          @click="showReceiverPicker = true"
          :required="true"
        />
        <van-popup v-model="showReceiverPicker" position="bottom" round>
          <address-list
            type="receiver"
            :districtId="order.base.to_district_id"
            @click-item="handleClickReceiverAddressItem"
            @deleted="handleDeleteReceiverAddressitem"
          />
        </van-popup>
        <!-- 上传小票 -->
        <van-field :label="$t('order.upload_small_ticket')"
                   :required="true"
        >
          <template #input>
            <van-uploader
              v-model="order.detail.small_ticket"
              :max-size="5242880"
              :max-count="1"
            />
          </template>
        </van-field>
      </van-cell-group>
      <!-- 商品信息 -->
      <van-cell-group :title="$t('order.product_info')" class="mt-3">
        <van-cell
          is-link
          title=""
          :value="$t('order.input_product_info')"
          @click="handleOpenProductPopup"
        />
        <van-cell-group
          class="products-cell-group"
          :title="`商品${index+1}`"
          v-for="(product, index) in order.detail.products"
          :key="product.unique"
        >
          <van-cell :title="$t('product.name')" :value="product.product_name" />
          <van-cell v-if="product.product_en_name" :title="$t('product.en_name')" :value="product.product_en_name" />
          <van-cell :title="$t('product.brand')" :value="`${product.brand}` " />
          <van-cell :title="$t('product.spec')" :value="`${product.spec}` " />
          <van-cell :title="$t('product.category')" :value="product.category_text" />
          <van-cell :title="$t('product.price')" :value="product.price | currency(productCurrency)" />
          <van-cell :title="$t('product.quantity')" :value="`${product.product_count}` " />
          <van-cell :title="$t('product.gross_weight')" :value="`${product.gross_weight}kg` " />
          <van-cell :title="$t('product.origin_country')" :value="`${product.country_text}` " />
          <van-cell>
            <van-button plain size="small" class="ml-1 float-right" type="primary" @click.prevent="handleOpenProductPopup($event, product)">{{ $t('common.edit') }}</van-button>
            <van-button plain size="small" class="float-right" type="danger" @click.prevent="removeProduct(product)">{{ $t('common.delete') }}</van-button>
          </van-cell>
        </van-cell-group>
      </van-cell-group>
      <!-- 商品信息弹窗 -->
      <van-popup
        v-model="showProductPopup"
        position="right"
        get-container="#app"
        :style="{ width: '100%', height: '100%' }"
      >
        <van-nav-bar
          :title="$t('order.input_product_info')"
          left-arrow
          @click-left="showProductPopup = false"
        />
        <van-field
          readonly
          clickable
          required
          :value="product.category_text"
          @click="showProductCategroyPicker = true"
          :placeholder="$t('common.click_for_select_field', { field: $t('product.category') })"
          :label="$t('product.category')"
        />
        <van-cell-group>
          <van-field
            :label="$t('product.name')"
            type="textarea"
            autosize
            :rows="1"
            v-model="product.product_name"
            maxlength="50"
            required
            :placeholder="$t('common.input_field_placeholder', { field: $t('product.name') })"
            :rules="[{ required: true, message: $t('product.rule_messages.name_required') }]"
          />
          <van-field
            :label="$t('product.en_name')"
            v-if="order && order.base.to_district_id !== 1"
            type="textarea"
            autosize
            :rows="1"
            v-model="product.product_en_name"
            maxlength="50"
            required
            :placeholder="$t('common.input_field_placeholder', { field: $t('product.en_name') })"
            :rules="[{ required: true, message: $t('product.rule_messages.en_name_required') }]"
          />
          <van-field
            :label="$t('product.brand')"
            type="textarea"
            autosize
            :rows="1"
            v-model="product.brand"
            maxlength="50"
            required
            :placeholder="$t('common.input_field_placeholder', { field: $t('product.brand') })"
            :rules="[{ required: true, message: $t('product.rule_messages.brand_required') }]"
          />
          <van-field
            :label="$t('product.spec')"
            type="textarea"
            autosize
            :rows="1"
            v-model="product.spec"
            maxlength="50"
            required
            :placeholder="`${$t('common.input_field_placeholder', { field: $t('product.spec') })} （${$t('common.spec_ex')}）`"
            :rules="[{ required: true, message: $t('product.rule_messages.spec_required') }]"
          />
          <van-field
            :label="$t('product.price')"
            v-model.number="product.price"
            type="number"
            required
            :placeholder="$t('product.placeholder.price')"
            :rules="[{ required: true, message: $t('product.rule_messages.price_required') }]"
          >
            <template #extra>
              {{ productCurrency }}
            </template>
          </van-field>
          <!--
          <van-field
            :label="$t('product.currency')"
            v-model="product.price"
            type="number"
          >
            <template #input>
              <van-radio-group v-model="product.currency">
                <van-radio
                  v-for="currency in currencies"
                  :key="currency"
                  :name="currency"
                  class="mb-1"
                >{{ currency }}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          -->

          <van-field :label="$t('product.quantity')">
            <template #input>
              <van-stepper
                v-model="product.product_count"
                :disabled="!product.product_category_id"
                input-width="6rem"
                :max="productCountMax"
                theme="round"
                integer
              />
            </template>
            <template #extra>
              <span v-if="(Object.is(product.unit, '千克') || Object.is(product.unit, '克'))"></span>
              <span v-else >{{ product.unit }}</span>
            </template>
          </van-field>
          <van-field :label="$t('product.gross_weight')">
            <template #input>
              <van-stepper v-model="product.gross_weight" :min="0.1" input-width="6rem" :max="weightLimited" theme="round" :decimal-length="1" :step="0.5" />
            </template>
            <template #extra>
              KG
            </template>
          </van-field>
          <van-field
             readonly
             clickable
             name="picker"
             :value="product.country_text"
             :label="$t('product.origin_country')"
             :placeholder="$t('product.origin_country_info')"
             @click="showCountryPicker = true"
          />
          <van-popup v-model="showCountryPicker" position="bottom">
            <van-picker
              ref="originCountryPicker"
              show-toolbar
              value-key="cn_name"
              :columns="originCountries"
              @cancel="showCountryPicker = false"
              @confirm="selectCountry"
            />
          </van-popup>
        </van-cell-group>
        <div class="mt-2 text-center text-danger">{{ $t('product.tips') }}</div>
        <div class="mt-2 text-center text-danger">{{ $t('product.tips2') }}</div>
        <div class="m-3">
          <van-button round block type="info" @click="saveProduct">
            {{ $t('common.confirmed') }}
          </van-button>
        </div>
      </van-popup>
      <!-- 商品分类-->
      <van-popup v-model="showProductCategroyPicker" round position="bottom">
        <van-picker
          ref="productCategoryPicker"
          class="product-category-picker"
          :title="$t('product.category')"
          show-toolbar
          :columns="productCategories"
          value-key="name"
          item-height="60px"
          @cancel="showProductCategroyPicker = false"
          @confirm="selectCategory"
        />
      </van-popup>
    </van-form>
    <van-form v-if="priceData" v-show="step === 2">
      <van-notice-bar v-if="district.tax_free && productPriceSum > district.tax_free" color="#fff" background="#ee0a24" left-icon="info-o">
        {{ $t('order.tax_free_limit_exceed', { currency: productCurrency, price: district.tax_free }) }}
      </van-notice-bar>
      <van-cell-group :title="$t('order.price_detail')">
        <van-cell :title="$t('order.prices.base')" :value="priceData.base | currency" />
        <van-cell :title="$t('order.prices.peak_season')" :value="priceData.peak_season | currency" v-if="priceData.peak_season" />
        <van-cell :title="$t('order.prices.fuel_surcharge')" :value="priceData.fuel_surcharge | currency" v-if="priceData.fuel_surcharge" />
        <van-cell :title="$t('order.prices.disposal')" :value="priceData.disposal | currency" v-if="priceData.disposal" />
        <van-cell :title="$t('order.prices.weight_overload')" :value="priceData.weight_overload | currency" v-if="priceData.weight_overload" />
        <van-cell :title="$t('order.prices.size_overload')" :value="priceData.size_overload | currency" v-if="priceData.size_overload" />
        <van-cell :title="$t('order.prices.remote')" :value="priceData.remote | currency" v-if="priceData.remote" />
        <van-cell :title="$t('order.prices.residential')" :value="priceData.residential | currency" v-if="priceData.residential" />
        <van-cell :title="$t('order.prices.tax')" :value="priceData.tax | currency" v-if="priceData.tax" />
      </van-cell-group>

      <!-- 优惠券 -->
      <van-cell-group :title="$t('coupon.coupon')">
        <van-coupon-cell
          :coupons="enableCoupons"
          :chosen-coupon="chosenCoupon"
          @click="showCouponPopup = true"
          currency="HK$"
        />
      </van-cell-group>
      <van-popup
        v-model="showCouponPopup"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px;"
      >
        <van-coupon-list
          :coupons="enableCoupons"
          :disabled-coupons="disableCoupons"
          :chosen-coupon="chosenCoupon"
          :show-close-button="false"
          @change="handleChangeCoupon"
          @exchange="handleExchageCoupon"
        />
        <div class="coupon-list-button p-2">
          <van-button type="danger" round block @click.prevent="handleChangeCoupon(-1)">
            {{ $t('coupon.no_use_coupon') }}
          </van-button>
        </div>
      </van-popup>
      <!-- 协议 -->
      <van-cell-group class="mt-4">
        <van-cell>
          <van-checkbox v-model="termsAccept">
            {{ $t('order.read_and_agree')}}
            <a href="javascript: void(0);" @click="showTermsPopup = true">{{ $t('order.terms') }}</a>
          </van-checkbox>
        </van-cell>
        <van-cell v-if="this.order && this.order.base.to_district_id === 1">
          <van-checkbox v-model="tipAccept">
            {{ $t('order.send_tip')}}
          </van-checkbox>
        </van-cell>
      </van-cell-group>
      <van-popup
        v-model="showTermsPopup"
        closeable
        round
        position="bottom"
        style="height: 60%;"
      >
        <div class="terms-info" v-if="terms">
          <h4>{{ terms.title }}</h4>
          <div class="content" v-html="terms.content"></div>
        </div>
        <div class="terms-button p-1">
          <van-button type="danger" block round @click="showTermsPopup = false; termsAccept = true">
            {{ $t('order.read_and_agree')}}
          </van-button>
        </div>
      </van-popup>
    </van-form>
    <!-- 提交 -->
    <van-submit-bar
        :price="price * 100"
        :disabled="submitDisabled"
        @submit="nextStep"
        :button-text="step === 2 ? $t('order.confirm_order') : $t('order.next_step')"
        currency="HK$"
        :label="$t('order.price')"
    >
      <template #default>
        <van-button plain round type="primary" v-show="step !== 0" @click.prevent="step--">
          {{ $t('order.prev_step') }}
        </van-button>
      </template>
    </van-submit-bar>
    <van-popup v-model="showDistrictPicker" position="bottom" get-container="#app" round>
      <van-picker
          ref="districtPicker"
          show-toolbar
          :columns="districtFiltered"
          value-key="name"
          @confirm="districtConfirmed"
          @cancel="showDistrictPicker = false"
      >
        <template #columns-top>
          <van-field v-model="districtQueryString" :placeholder="$t('order.district_filter')" />
        </template>
      </van-picker>
    </van-popup>
    <van-popup
      v-model="showUserAgreementPopup"
      round
      position="bottom"
      :close-on-click-overlay="false"
      style="height: 60%;"
    >
      <div class="terms-info" v-if="userAgreement">
        <h4>{{ userAgreement.title }}</h4>
        <div class="content" v-html="userAgreement.content"></div>
      </div>
      <div class="terms-button p-1">
        <div class="row">
          <div class="col-4">
            <van-button type="default" block round @click="$router.push('/')">
              {{ $t('common.disagree')}}
            </van-button>
          </div>
          <div class="col-8">
            <van-button type="danger" block round @click="showUserAgreementPopup = false">
              {{ $t('common.agree')}}
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';

import { mapState, mapGetters } from 'vuex';
import {
  SubmitBar,
  NavBar,
  CouponCell,
  CouponList,
  Step,
  Steps,
  DatetimePicker,
  NoticeBar,
} from 'vant';

import AddressList from '@/components/address/AddressList';
import { formatMoney, Probability, showErrorMessage } from '@/helpers';

Vue.use(NavBar);
Vue.use(SubmitBar);
Vue.use(CouponCell);
Vue.use(CouponList);
Vue.use(Step);
Vue.use(Steps);
Vue.use(DatetimePicker);
Vue.use(NoticeBar);

/* global wx */

const productTemplate = {
  product_name: '',
  product_en_name: '',
  product_category_id: null,
  country_code: '110',
  country_text: '香港',
  category_text: '',
  product_count: 1,
  gross_weight: 0.1,
  price: '',
  // currency: 'HKD',
  unit: '',
  postal_tax_number: '',
};

export default {
  name: 'CreateOrderV2',
  mounted() {
    this.$api.app.jsSdk().then(({ data: response }) => {
      wx.config({
        debug: response.debug,
        appId: response.appId,
        timestamp: response.timestamp,
        nonceStr: response.nonceStr,
        signature: response.signature,
        jsApiList: response.jsApiList,
      });
    });
    this.$api.app.districtRoot().then(({ data: response }) => {
      this.districtList = response.data;
      const china = _.find(this.districtList, { id: 1 });
      if (!this.order.base.to_district_id) {
        this.order.base.to_district_id = china.id;
        this.districtText = china.name;
        this.district = china;
        this.groupSpxMaxWeight = china.spx_max_weight ? china.spx_max_weight : 999;
        this.groupDocMaxWeight = china.doc_max_weight ? china.doc_max_weight : 999;
        this.groupMaxWeight = china.max_weight ? china.max_weight : 999;
        this.groupMaxSize = china.max_size ? china.max_longest_size : 999;
        this.groupMaxLongestSize = china.max_longest_size ? china.max_longest_size : 999;
      }
    });
    this.$store.dispatch('fetchProductCategories');
    this.$store.dispatch('fetchOriginCountries');
    this.$store.dispatch('fetchMyCoupons');

    (new Probability({
      p: this.terms ? '60%' : '100%',
      f: () => this.$store.dispatch('fetchTerms'),
    }))();
    (new Probability({
      p: this.userAgreement ? '60%' : '100%',
      f: () => this.$store.dispatch('fetchUserAgreement'),
    }))();
    if (sessionStorage.getItem('create-order-cache')) {
      this.$dialog.confirm({
        message: this.$t('order.load_cache_confirm'),
      }).then(() => {
        this.loadCache();
      }).catch(() => {
        sessionStorage.removeItem('create-order-cache');
      });
    }
    this.order.detail.expected_post_date = moment().format('YYYY-MM-DD');
  },
  data() {
    return {
      groupSpxMaxWeight: 2,
      groupDocMaxWeight: 0.5,
      groupMaxSize: 0,
      groupMaxLongestSize: 0,
      step: 0,
      termsAccept: false,
      tipAccept: false,
      districtList: [],
      parcelSizes: [
        // todo
        {
          key: 1, long: 20, width: 20, height: 10, weight: 0.5,
        },
        // {
        //   key: 1, long: 20, width: 2, height: 30, weight: 1,
        // },
        // {
        //   key: 2, long: 35, width: 2, height: 30, weight: 2,
        // },
        // {
        //   key: 3, long: 40, width: 3, height: 30, weight: 3,
        // },
      ],
      preset: null,
      district: null,
      districtText: '',
      showCountryPicker: false,
      showDistrictPicker: false,
      post_office: null,
      expectedPostDate: {
        value: new Date(),
        min: new Date(),
        max: moment().add(7, 'd').toDate(),
      },
      order: {
        base: {
          long: null,
          width: null,
          weight: null,
          height: null,
          to_district_id: null,
          type: 'spx',
        },
        detail: {
          type: 1,
          post_office_code: '',
          post_office_id: null,
          expected_post_date: null,
          coupon_id: null,
          sender_address_id: null,
          receiver_address_id: null,
          small_ticket: [],
          products: [],
        },
      },
      priceData: null,
      chosenCoupon: -1,
      sender: '',
      receiver: '',
      showSenderPicker: false,
      showReceiverPicker: false,
      showProductCategroyPicker: false,
      showExpectedPostDatePicker: false,
      showProductPopup: false,
      showCouponPopup: false,
      showTermsPopup: false,
      showUserAgreementPopup: true,
      product: { ...productTemplate },
      productPriceSum: 0,
      productCountMax: 99,
      useCoupon: null,
      user_coupon_id: null,
      product_validate: false,
      districtQueryString: '',
    };
  },
  computed: {
    ...mapGetters(['postOfficeParcelModels', 'pickupParcelModels']),
    ...mapState({
      originCountries: state => state.app.originCountries,
      senderAddress: state => state.address.senderAddress,
      receiverAddress: state => state.address.receiverAddress,
      productCategories: state => state.app.productCategories,
      myCoupons: state => state.user.myCoupons,
      terms: state => state.app.terms,
      userAgreement: state => state.app.userAgreement,
    }),
    productCurrency() {
      return this.district.currency || 'HKD';
    },
    price() {
      if (this.priceData) {
        return this.priceData.total === this.priceData.discount ? this.priceData.total : this.priceData.discount;
      }
      return 0;
    },
    // 可用优惠券
    enableCoupons() {
      console.log('enableCoupons');
      console.log(this.myCoupons);
      /* eslint-disable no-param-reassign */
      const coupons = [];
      const now = ~~(Date.now() / 1000);
      if (this.priceData.total && this.myCoupons.length) {
        this.myCoupons.forEach((item) => {
          if (now <= item.validity_period_timestamp[0] || now >= item.validity_period_timestamp[1]) {
            // 過期
            item.reason = this.$t('coupon.disable_reasons.expired');
          } else if (this.priceData.total < item.use_amount_condition) {
            // 未達到條件
            item.reason = this.$t('coupon.disable_reasons.amount_condition', { price: this.price });
          } else if (item.district_ids && item.district_ids.length && !~item.district_ids.indexOf(this.order.base.to_district_id)) {
            // 限制地区
            item.reason = this.$t('coupon.disable_reasons.district');
          } else {
            coupons.push(this.formatCouponData(item));
          }
        });
      }
      console.log(coupons);
      return coupons;
    },
    // 不可用优惠券
    disableCoupons() {
      const coupons = [];
      const enableIds = _.map(this.enableCoupons, 'id');
      this.myCoupons.forEach((item) => {
        if (~enableIds.indexOf(item.user_coupon_id)) {
          return;
        }
        coupons.push(this.formatCouponData(item));
      });
      return coupons;
    },
    submitDisabled() {
      if (this.step === 0) {
        return !this.price;
      }
      if (this.step === 1) {
        if (!this.product_validate) {
          return true;
        }
        if (this.order.detail.type === 1) {
          return !(this.order.detail.post_office_id && this.order.detail.sender_address_id
            && this.order.detail.receiver_address_id && this.order.detail.products.length);
        }
        return !(this.order.detail.sender_address_id
          && this.order.detail.receiver_address_id && this.order.detail.products.length);
      }
      if (this.step === 2) {
        if (this.order.base.to_district_id === 1) {
          return !(this.termsAccept && this.tipAccept);
        }
        return !this.termsAccept;
      }
      return true;
    },
    // 重量限制
    weightLimited() {
      if (this.order.base.type === 'spx') {
        return this.groupSpxMaxWeight;
      }
      return this.groupDocMaxWeight;
      // if (this.order.base.to_district_id === 1) {
      //   return this.order.base.type === 'spx' ? 3 : 0.5;
      // }
      // return 100;
    },
    districtFiltered() {
      if (this.districtQueryString) {
        return this.districtList.filter(item => ~item.name.indexOf(this.districtQueryString));
      }
      return this.districtList;
    },
  },
  watch: {
    'order.base': {
      handler(value) {
        if (value.long && value.width && value.height && value.width && value.weight && value.to_district_id && value.type) {
          this.quotedPrice();
        }
      },
      deep: true,
    },
    step() {
      this.storeCache();
    },
  },
  methods: {
    loadCache() {
      const data = sessionStorage.getItem('create-order-cache');
      if (data) {
        const cached = JSON.parse(data);
        this.$nextTick(() => {
          _.forOwn(cached.order, (value, key) => {
            this.$set(this.order, key, value);
          });
          this.step = cached.step;
          this.district = cached.district;
          this.districtText = cached.districtText;
          this.post_office = cached.post_office;
          this.sender = cached.sender;
          this.receiver = cached.receiver;
          this.productsValidation(this.order.detail.products);
        });
      }
    },
    storeCache() {
      const data = _.pick(this, ['order', 'district', 'districtText', 'post_office', 'step', 'sender', 'receiver']);
      sessionStorage.setItem('create-order-cache', JSON.stringify(data));
    },
    selectedPresetSize(pm) {
      this.order.base.long = pm.long;
      this.order.base.width = pm.width;
      this.order.base.height = pm.height;
      this.order.base.weight = _.min([pm.weight, this.weightLimited]);
      this.preset = pm.key;
    },
    districtConfirmed(values) {
      this.order.base.to_district_id = values.id;
      this.groupSpxMaxWeight = values.spx_max_weight ? values.spx_max_weight : 999;
      this.groupDocMaxWeight = values.doc_max_weight ? values.doc_max_weight : 999;
      this.groupMaxSize = values.max_size ? values.max_longest_size : 999;
      this.groupMaxLongestSize = values.max_longest_size ? values.max_longest_size : 999;
      this.district = values;
      this.districtText = values.name;
      this.showDistrictPicker = false;
    },
    handleChangeType() {
      this.post_office = null;
      this.order.post_office_id = null;
    },
    scanPostOfficeCode() {
      wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode'],
        success: ({ resultStr, errMsg }) => {
          if (errMsg === 'scanQRCode:ok') {
            let code;
            if (resultStr.startsWith('ESS-PO-CODE:')) {
              code = resultStr.substring(resultStr.indexOf(':') + 1);
            } else {
              code = resultStr;
            }
            this.checkPostOfficeCode(code);
          }
        },
        fail: () => {
        },
      });
    },
    handlePostOfficeCodeBlur(event) {
      if (event.target.value) {
        this.checkPostOfficeCode(event.target.value);
      } else {
        this.post_office = null;
      }
    },
    checkPostOfficeCode(code) {
      if (code) {
        this.$api.postOffice.get(code).then(({ data: response }) => {
          this.post_office = response.data;
          this.order.detail.post_office_id = this.post_office.id;
        }).catch((e) => {
          this.$toast.fail(e.response.data.message);
          this.post_office = null;
        }).finally(() => {
          //
        });
      }
    },
    handleClickSenderAddressItem(item) {
      if (item) {
        this.order.detail.sender_address_id = item.id;
        this.sender = `${item.name} ${item.tel}`;
      } else {
        this.order.detail.sender_address_id = null;
        this.sender = '';
      }
      this.showSenderPicker = false;
    },
    handleDeleteSenderAddressitem(id) {
      if (id === this.order.sender_address_id) {
        this.handleClickSenderAddressItem(false);
      }
    },
    handleClickReceiverAddressItem(item) {
      /*
      if (item) {
        if (!item.hasCardInfo) {
          this.$toast({
            type: 'fail',
            message: this.$t('address.supplement_card'),
            onClose: () => {
              this.$router.push({ name: 'address' });
            },
          });
        } else {
          this.order.detail.receiver_address_id = item.id;
          this.receiver = `${item.name} ${item.tel} ${item.address}`;
        }
      } else {
        this.order.receiver_address_id = null;
        this.receiver = '';
      }
      */
      this.order.detail.receiver_address_id = item.id;
      this.receiver = `${item.name} ${item.tel} ${item.address}`;
      this.showReceiverPicker = false;
    },
    handleDeleteReceiverAddressitem(id) {
      if (id === this.order.receiver_address_id) {
        this.handleClickReceiverAddressItem(false);
      }
    },
    handleOpenProductPopup(event, item) {
      if (item) {
        // 修改
        const product = _.find(this.order.detail.products, { unique: item.unique });
        if (product) {
          this.product = { ...product };
          this.showProductPopup = true;
        }
      } else {
        // 添加
        // eslint-disable-next-line
        if (this.order.detail.products.length >= 3) {
          this.$toast.fail(this.$t('order.product_limit', { num: 3 }));
        } else {
          this.product = { ...productTemplate };
          this.showProductPopup = true;
        }
      }
    },
    handleConfirmExpectedPostDate(date) {
      this.order.detail.expected_post_date = moment(date).format('YYYY-MM-DD');
      this.showExpectedPostDatePicker = false;
    },
    saveProduct() {
      if (!this.product.product_name) {
        this.$toast.fail(this.$t('product.rule_messages.name_required'));
        return;
      }
      if (this.order.base.to_district_id !== 1 && !this.product.product_en_name) {
        this.$toast.fail(this.$t('product.rule_messages.en_name_required'));
        return;
      }
      if (!this.product.brand) {
        this.$toast.fail(this.$t('product.rule_messages.brand_required'));
        return;
      }
      if (!this.product.spec) {
        this.$toast.fail(this.$t('product.rule_messages.spec_required'));
        return;
      }
      if (!this.product.product_category_id) {
        this.$toast.fail(this.$t('product.rule_messages.category_required'));
        return;
      }
      if (!this.product.price) {
        this.$toast.fail(this.$t('product.rule_messages.price_required'));
        return;
      }
      if (!this.product.unique) {
        this.product.unique = Date.now();
        this.product.currency = this.productCurrency;
        this.order.detail.products.push(this.product);
        this.productsValidation(this.order.detail.products);
        this.showProductPopup = false;
      } else {
        const index = _.findIndex(this.order.detail.products, { unique: this.product.unique });
        this.order.detail.products[index] = this.product;
        this.productsValidation(this.order.detail.products);
        this.showProductPopup = false;
      }
    },
    removeProduct(product) {
      this.$dialog.confirm({
        title: this.$t('product.confirm_delete'),
      }).then(() => {
        this.order.detail.products = _.reject(this.order.detail.products, { unique: product.unique });
        this.productsValidation(this.order.detail.products);
      });
    },
    productsValidation(products) {
      this.productPriceSum = _.sumBy(products, product => Number(product.price) * product.product_count);
      if (this.order.base.to_district_id === 1) {
        let shoeCount = 0;
        let productCount = 0;
        products.forEach((product) => {
          if (/^0602\d+/.test(product.postal_tax_number)) {
            shoeCount += product.product_count;
          }
          productCount += product.product_count;
        });
        // console.log(`productCount:${productCount}`);
        // console.log(`productPriceSum:${this.productPriceSum}`);
        if (shoeCount > 1) {
          this.$dialog.alert({
            title: this.$t('order.product_limit_tips'),
            message: this.$t('order.declare_shoe_cannot_exceed'),
          });
          this.product_validate = false;
          return;
        }
        if (productCount === 1 && this.productPriceSum > 5000) {
          this.$dialog.alert({
            title: this.$t('order.product_limit_tips'),
            message: this.$t('order.declare_single_product_cannot_exceed', { price: 5000 }),
          });
          this.product_validate = false;
          return;
        }
        if (productCount !== 1 && this.productPriceSum > this.district.tax_free) {
          this.$dialog.alert({
            title: this.$t('order.product_limit_tips'),
            message: this.$t('order.declare_multiple_products_cannot_exceed', { price: this.district.tax_free }),
          });
          this.product_validate = false;
          return;
        }
      } else if (this.district.tax_free && this.productPriceSum > this.district.tax_free) {
        this.$dialog.alert({
          message: this.$t('order.tax_free_limit_exceed', { currency: this.productCurrency, price: this.district.tax_free }),
        });
      }
      this.product_validate = true;
    },
    selectCategory(values, indexes) {
      const category = this.$refs.productCategoryPicker.getColumnValues(indexes.length - 1);
      const index = indexes[indexes.length - 1];
      this.product.product_category_id = category[index].id;
      this.product.category_text = values.join('/');
      this.product.unit = category[index].unit;
      // this.product.spec = this.product.unit;
      this.product.postal_tax_number = category[index].postal_tax_number;
      // 商品數量限制
      if (/^0602\d+/.test(this.product.postal_tax_number)) {
        // 鞋類
        this.productCountMax = 1;
      } else {
        this.productCountMax = 3;
      }
      this.showProductCategroyPicker = false;
    },
    selectCountry(values) {
      this.product.country_code = values.code;
      this.product.country_text = values.cn_name;
      // this.product.postal_tax_number = category[index].postal_tax_number;
      // 商品數量限制
      this.showCountryPicker = false;
    },
    formatCouponData(coupon) {
      let valueDesc;
      let unitDesc;
      let condition;
      let description = '';
      let value = 0;
      if (coupon.discount_price) {
        value = coupon.discount_price * 100;
        valueDesc = formatMoney(coupon.discount_price, 'HKD');
        unitDesc = '';
      } else if (coupon.discount_percent) {
        value = this.priceData.base * (coupon.discount_percent / 100) * 100;
        valueDesc = coupon.discount_percent;
        unitDesc = '% OFF';
      }
      if (coupon.use_amount_condition) {
        condition = this.$t('coupon.use_amount_condition', { price: coupon.use_amount_condition });
      } else {
        condition = this.$t('coupon.no_condition');
      }
      if (coupon.new_user_only) {
        description = this.$t('coupon.new_user_only');
      }

      return {
        id: coupon.user_coupon_id,
        name: coupon.name,
        condition,
        description,
        startAt: coupon.validity_period_timestamp[0],
        endAt: coupon.validity_period_timestamp[1],
        reason: coupon.reason ? coupon.reason : '',
        value,
        valueDesc,
        unitDesc,
      };
    },
    handleChangeCoupon(index) {
      console.log(this.enableCoupons);
      if (~index) {
        const couponSelected = this.enableCoupons[index];
        this.useCoupon = _.find(this.myCoupons, { user_coupon_id: couponSelected.id });
        this.user_coupon_id = this.useCoupon.user_coupon_id;
      } else {
        this.useCoupon = null;
        this.user_coupon_id = null;
      }
      console.log(index);
      console.log(this.useCoupon);
      console.log(this.user_coupon_id);
      this.chosenCoupon = index;
      this.showCouponPopup = false;
      this.quotedPrice();
    },
    handleExchageCoupon(code) {
      this.$store.dispatch('exchangeCoupon', code).then(() => {
        console.log(this.myCoupons);
        this.$toast.success(this.$t('coupon.exchanged'));
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        //
      });
    },
    quotedPrice() {
      const params = {
        ...this.order.base,
      };
      if (this.order.detail.receiver_address_id) {
        params.receiver_address_id = this.order.detail.receiver_address_id;
      }
      if (this.order.detail.products.length) {
        params.order_items = this.order.detail.products;
      }
      if (this.user_coupon_id) {
        params.coupon_id = this.user_coupon_id;
      }
      return this.$api.order.quotedPrice(params).then(({ data: response }) => {
        this.priceData = response;
      }).catch((e) => {
        this.$toast.fail(e.response.data.message);
        this.priceData.total = 0;
      });
    },
    nextStep() {
      /* eslint-disable default-case */
      switch (this.step) {
        case 0:
          this.step = 1;
          break;
        case 1:
          this.$refs.order_detail.validate().then(() => {
            this.quotedPrice().then(() => {
              this.step = 2;
            });
          });
          break;
        case 2:
          this.submitOrder();
          break;
      }
    },
    submitOrder() {
      if (!this.order.detail.sender_address_id) {
        this.$toast.fail(this.$t('order.rule_messages.sender_address_id_required'));
        return false;
      }
      if (!this.order.detail.receiver_address_id) {
        this.$toast.fail(this.$t('order.rule_messages.receiver_address_id_required'));
        return false;
      }
      if (!this.order.detail.products.length) {
        this.$toast.fail(this.$t('order.rule_messages.products_required'));
        return false;
      }
      if (this.order.base.to_district_id === 1 && !this.order.detail.small_ticket.length) {
        this.$toast.fail(this.$t('order.rule_messages.ticket_required'));
        return false;
      }
      const fd = new FormData();
      fd.append('type', this.order.detail.type);
      fd.append('long', this.order.base.long);
      fd.append('width', this.order.base.width);
      fd.append('height', this.order.base.height);
      fd.append('weight', this.order.base.weight);
      fd.append('parcel_type', this.order.base.type);
      if (this.order.detail.type === 1) {
        fd.append('post_office_id', this.order.detail.post_office_id);
        fd.append('expected_post_date', this.order.detail.expected_post_date);
      }
      fd.append('sender_address_id', this.order.detail.sender_address_id);
      fd.append('receiver_address_id', this.order.detail.receiver_address_id);
      if (this.order.detail.small_ticket.length) {
        fd.append('small_ticket', this.order.detail.small_ticket[0].file);
      }
      this.order.detail.products.forEach((product, key) => {
        // if (product.unit === '千克') {
        //   fd.append(`order_items[${key}][gross_weight]`, product.product_count);
        // } else {
        //   fd.append(`order_items[${key}][gross_weight]`, product.gross_weight);
        // }
        fd.append(`order_items[${key}][gross_weight]`, product.gross_weight);
        fd.append(`order_items[${key}][product_category_id]`, product.product_category_id);
        fd.append(`order_items[${key}][product_name]`, product.product_name);
        fd.append(`order_items[${key}][product_name_en]`, product.product_en_name);
        fd.append(`order_items[${key}][product_count]`, product.product_count);
        fd.append(`order_items[${key}][price]`, product.price);
        fd.append(`order_items[${key}][unit]`, product.unit);
        fd.append(`order_items[${key}][currency]`, this.productCurrency);
        fd.append(`order_items[${key}][origin_country]`, product.country_code);
        fd.append(`order_items[${key}][brand]`, product.brand);
        fd.append(`order_items[${key}][spec]`, product.spec);
      });
      if (this.user_coupon_id) {
        fd.append('coupon_id', this.user_coupon_id);
      }
      this.$toast.loading({
        message: this.$t('common.saving'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.order.create(fd).then(({ data: response }) => {
        this.$dialog.alert({
          title: this.$t('order.ordered'),
        }).then(() => {
          this.$router.push({ name: 'order-detail', params: { id: response.data.id } });
        });
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        sessionStorage.removeItem('create-order-cache');
        this.$toast.clear();
      });
      return true;
    },
  },
  components: {
    AddressList,
  },
};
</script>

<style lang="scss">
  @import "../../../style/variables";
  #price-v2 {
    .weight-label {
      margin-left: 1rem;
    }
    .van-field__control {
      display: grid!important;
    }
    padding-bottom: 34px;
    .product-category-picker {
      .van-ellipsis {
        white-space: pre-wrap;
      }
    }
    .discount-info {
      .origin-price {
        text-decoration: line-through;
        color: $gray-7;
      }
      .discount-price {
        margin-left: 1rem;
      }
    }

    .van-coupon-list {
      padding-bottom: 60px;
      &__bottom {
        padding: 0;
      }
    }
    .coupon-list-button {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 100;
    }
    .terms-container {
      font-size: .6rem;
    }
    .terms-info {
      padding: 0 1rem;
      height: 85%;
      overflow: scroll;
      h4 {
        text-align: center;
      }
      .content {
        font-size: .8rem;
        img {
          width: 100%;
        }
      }
    }
    .terms-button {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 999;
    }
  }
</style>

<template>
  <div id="order-detail">
    <van-nav-bar
      :title="$t('order_detail.title')"
      left-arrow
      @click-left="() => $router.back()"
    />
    <van-pull-refresh v-model="refreshing" @refresh="fetchOrder">
      <template v-if="order">
        <van-cell-group>
          <van-cell v-if="order.unpaid_additional_fees_count">
            <span class="text-warning">
              <van-icon name="warning" />
              {{ $t('order.additional_fee_unpaid_tips', { num: order.unpaid_additional_fees_count }) }}
            </span>
          </van-cell>
          <van-cell v-if="order.issue">
            <span class="text-danger">
              <van-icon name="warning" />
            {{ order.issue }}
            </span>
          </van-cell>
          <van-cell v-if="order.duty_paid">
            <span class="text-danger">{{ $t('DDP.tips') }}</span>
            <ol class="text-danger">
              <li>{{ $t('DDP.step1') }}</li>
              <li>{{ $t('DDP.step2') }}</li>
            </ol>
            <div class="text-center">
              <van-button
                plain
                hairline
                type="primary"
                size="small"
                class="mr-2"
                url="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5NzU1NDQwMg=="
              >
                {{ $t('DDP.wechat') }}
              </van-button>
              <van-button
                plain
                hairline
                type="info"
                size="small"
                url="http://ghzx.gdems.com/ghzx/common/index.jsp"
              >
                {{ $t('DDP.web') }}
              </van-button>
            </div>
          </van-cell>
          <van-cell :class="`order-status-${order.status_str}`" :title="$t('order.status')" :value="$t(`order_status.${order.status_str}`)"></van-cell>
          <van-cell v-for="fee in order.additional_fees" :key="fee.id">
            <template #title>
              {{ $t('order.additional_fee') }} : {{ fee.name }}
            </template>
            {{ $t('order_detail.additional_fee.price') }}: {{ fee.price | currency }}<br />
            <template v-if="fee.reason">{{ $t('order_detail.additional_fee.reason') }}: {{ fee.reason }}<br /></template>
            {{ $t('order_detail.additional_fee.payment_status') }}: {{ $t(`payment.status.${fee.payment_status}`) }}<br />
            <van-button type="primary" size="small" round @click="additionalFeeWechatpay(fee.id)" v-if="fee.payment_status !== 1">{{ $t('payment.wechatpay') }}</van-button>
          </van-cell>
          <van-cell :title="$t('order.parcel_code')" :value="order.parcel_code"></van-cell>
          <van-cell v-if="order.expected_post_date" :title="$t('order.expected_post_date')" :value="order.expected_post_date"></van-cell>
          <van-cell>
            <div class="text-right">
              <img :src="`data:image/png;base64,${order.parcel_barcode}`" class="img-fluid" />
            </div>
          </van-cell>
          <!--
          <van-cell :title="$t('order.parcel_price')" :value="order.parcel_price | currency"></van-cell>
          <van-cell :title="$t('order.tax')" :value="order.tax | currency"></van-cell>
          -->
          <template v-if="order.price_detail">
            <van-cell :title="$t('order.prices.base')" :value="order.price_detail.base | currency" v-if="order.price_detail.base" />
            <van-cell :title="$t('order.prices.peak_season')" :value="order.price_detail.peak_season | currency" v-if="order.price_detail.peak_season" />
            <van-cell :title="$t('order.prices.fuel_surcharge')" :value="order.price_detail.fuel_surcharge | currency" v-if="order.price_detail.fuel_surcharge" />
            <van-cell :title="$t('order.prices.disposal')" :value="order.price_detail.disposal | currency" v-if="order.price_detail.disposal" />
            <van-cell :title="$t('order.prices.weight_overload')" :value="order.price_detail.weight_overload | currency" v-if="order.price_detail.weight_overload" />
            <van-cell :title="$t('order.prices.size_overload')" :value="order.price_detail.size_overload | currency" v-if="order.price_detail.size_overload" />
            <van-cell :title="$t('order.prices.remote')" :value="order.price_detail.remote | currency" v-if="order.price_detail.remote" />
            <van-cell :title="$t('order.prices.residential')" :value="order.price_detail.residential | currency" v-if="order.price_detail.residential" />
            <van-cell :title="$t('order.prices.tax')" :value="order.price_detail.tax | currency" v-if="order.price_detail.tax" />
          </template>
          <van-cell :title="$t('order.sender')" center>
            {{ order.sender_address.name }}<br />
            {{ order.sender_address.phone }}<br />
            <template v-if="order.type === 1">
              {{ order.post_office.name }}<br />
              {{ order.post_office.district_name }}<br />
              {{ order.post_office.address }}
            </template>
            <template v-else>
              <span v-if="order.sender_address.district_name">
                {{ order.sender_address.district_name }}<br />
              </span>
              {{ order.sender_address.address }}
            </template>
          </van-cell>
          <van-cell :title="$t('order.receiver')" center>
            {{ order.receiver_address.name }}<br />
            {{ order.receiver_address.phone }}<br />
            {{ order.receiver_address.district }}<br />
            {{ order.receiver_address.address }}
          </van-cell>
          <van-cell :title="$t('order.small_ticket')" v-if="order.small_ticket_path" center>
            <van-image-preview v-model="smallTicketPreview" :images="[order.small_ticket_path]" :show-index="false" closeable />
            <van-image :src="order.small_ticket_path" height="100" fit="contain" @click="smallTicketPreview = true" />
          </van-cell>
        </van-cell-group>

        <van-cell-group
          class="products-cell-group"
          :title="`商品${index+1}`"
          v-for="(product, index) in order.order_items"
          :key="product.id"
        >
          <van-cell :title="$t('product.name')" :value="product.product_name" />
          <van-cell :title="$t('product.brand')" :value="`${product.brand}` " />
          <van-cell :title="$t('product.spec')" :value="`${product.spec}` " />
          <van-cell :title="$t('product.category')" :value="product.category_text" />
          <van-cell :title="$t('product.price')" :value="product.price | currency(product.currency)" />
          <van-cell :title="$t('product.tax')" :value="product.tax | currency(product.currency)" />
          <van-cell :title="$t('product.quantity')" :value="`${product.product_count} ${product.unit}` " />
          <van-cell :title="$t('product.gross_weight')" :value="`${product.gross_weight}kg` " />
          <van-cell :title="$t('product.post_tax_num')" :value="product.post_tax_num" />
        </van-cell-group>

        <van-submit-bar
          class="payment-bar"
          :price="price"
          currency="HK$"
          text-align="left"
          :label="$t('order.price')+':'"
          :button-text="$t('payment.wechatpay')"
          button-type="primary"
        >
          <template #button>
            <van-button size="small" type="danger" plain class="mr-1" :to="{ name: 'create-work-order', query: { parcel: order.parcel_code }}" round>{{ $t('workOrder.create') }}</van-button>
            <van-button size="small" type="info" v-if="checkTracking" @click="showTrackingPopup = true" round>{{ $t('order.view_tracking') }}</van-button>
            <van-button size="small" type="danger" v-if="cancelable" @click="cancel" round>{{ $t('order.cancel') }}</van-button>
            <van-button size="small" class="ml-1" v-if="paymentable" type="primary" round @click="wechatpay" :disabled="paymenting">{{ $t('payment.wechatpay') }}</van-button>
            <van-button size="small" class="ml-1 copy" type="primary" round @click="createInvoice">Invoice</van-button>
          </template>
        </van-submit-bar>

        <van-popup
          v-model="showTrackingPopup"
          position="right"
          get-container="#app"
          :style="{ width: '100%', height: '100%' }"
        >
          <van-nav-bar
            :title="$t('order.tracking_info')"
            left-arrow
            @click-left="showTrackingPopup = false"
          />
          <div class="p-3">
            <van-steps v-if="order.tracks.length" direction="vertical">
              <van-step v-for="item in order.tracks" :key="item.time + item.status">
                <div>{{ item.mailNo }}</div>
                <div>{{ item.message }}</div>
                <div>{{ item.time }}</div>
              </van-step>
            </van-steps>
          </div>
        </van-popup>
      </template>
    </van-pull-refresh>

    <van-popup
        style="width: 100%;height: 100%;"
        v-model="showInvoicePopup"
        closeable
    >
      <div class="content" v-html="invoiceHtml"></div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  NavBar, ImagePreview, SubmitBar, PullRefresh, Step, Steps,
} from 'vant';
import AppConfig from '@/app.config';
import { showErrorMessage } from '@/helpers';

Vue.use(NavBar);
Vue.use(ImagePreview);
Vue.use(SubmitBar);
Vue.use(PullRefresh);
Vue.use(Step);
Vue.use(Steps);

/* global wx */

export default {
  name: 'OrderDetail',
  mounted() {
    this.$api.app.jsSdk().then(({ data: response }) => {
      wx.config({
        debug: response.debug,
        appId: response.appId,
        timestamp: response.timestamp,
        nonceStr: response.nonceStr,
        signature: response.signature,
        jsApiList: response.jsApiList,
      });
    });
  },
  data() {
    return {
      invoiceHtml: null,
      showInvoicePopup: false,
      paymenting: false,
      showTrackingPopup: false,
      order: null,
      smallTicketPreview: false,
      refreshing: false,
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
    }),
    price() {
      if (this.order) {
        return this.order.discount_price * 100;
      }
      return 0;
    },
    paymentable() {
      if (this.order) {
        return this.order.status === 1;
      }
      return false;
    },
    cancelable() {
      if (this.order) {
        return this.order.status === 1 || this.order.status === 2;
      }
      return false;
    },
    checkTracking() {
      if (this.order) {
        return ~[3, 4, 5].indexOf(this.order.status);
      }
      return false;
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.fetchOrder();
      },
      immediate: true,
    },
  },
  methods: {
    createInvoice() {
      const url = `${window.location.protocol}${AppConfig.API_HOST}/order-invoice/${this.order.id}?token=${this.token}`;
      console.log(url);
      window.top.location.href = url;
      // this.$api.order.createInvoice(this.$route.params.id).then(({ data: response }) => {
      //   this.invoiceHtml = response;
      //   this.showInvoicePopup = true;
      // }).catch((e) => {
      //   showErrorMessage(e);
      // });
    },
    fetchOrder() {
      this.$toast.loading({
        message: this.$t('common.loading'),
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      this.$api.order.getById(this.$route.params.id).then(({ data: response }) => {
        this.order = response.data;
        this.refreshing = false;
        // 未支付
        if (this.order.status < 2) {
          this.wechatpay();
        }
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        this.$toast.clear();
      });
    },
    cancel() {
      this.$dialog.confirm({
        message: this.$t('order.cancel_comfirm'),
      }).then(() => {
        this.$api.order.remove(this.$route.params.id).then(() => {
          this.$toast.success(this.$t('order.order_canceled'));
          this.$router.push({ name: 'orders' });
        }).catch((e) => {
          showErrorMessage(e);
        });
      }).catch(() => {});
    },
    wechatpay() {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: ({ checkResult }) => {
          if (checkResult.chooseWXPay) {
            this.paymenting = true;
            this.$api.order.wechatpay(this.$route.params.id).then(({ data: response }) => {
              wx.chooseWXPay({
                timestamp: response.timeStamp,
                nonceStr: response.nonceStr,
                package: response.package,
                signType: response.signType,
                paySign: response.paySign,
                success: () => {
                  // res: { errMsg: 'chooseWXPay:ok' }
                  this.$toast.loading({
                    message: this.$t('common.loading'),
                    forbidClick: true,
                    duration: 0,
                    overlay: true,
                  });
                  setTimeout(() => {
                    this.$api.order.wechatpayQuery(this.$route.params.id).then(({ data: resp }) => {
                      this.order = resp.data;
                      let message = '';
                      if (this.order.type === 1) {
                        message = this.$t('order.payment_success_tips1');
                      } else if (this.order.type === 2) {
                        message = this.$t('order.payment_success_tips2', { code: this.order.parcel_code });
                      }
                      this.$dialog.alert({
                        title: this.$t('payment.payment_success'),
                        message,
                      }).then(() => {
                        if (this.order.type === 1) {
                          this.$router.push({ name: 'order-index' });
                        }
                        if (this.order.type === 2) {
                          this.$router.push({ name: 'pickup-order' });
                        }
                      });
                    }).catch((e) => {
                      showErrorMessage(e);
                    }).finally(() => {
                      this.paymenting = false;
                      this.$toast.clear();
                    });
                  }, 3000);
                },
                cancel: () => {
                  this.paymenting = false;
                  this.$api.order.wechatpayCancel(this.$route.params.id).then(() => {
                    this.$toast.fail(this.$t('payment.payment_cancel'));
                  });
                },
                fail: () => {
                  this.paymenting = false;
                  this.$toast.fail(this.$t('payment.payment_fail'));
                },
              });
            }).catch((e) => {
              showErrorMessage(e);
            });
          }
        },
      });
    },
    additionalFeeWechatpay(id) {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'],
        success: ({ checkResult }) => {
          if (checkResult.chooseWXPay) {
            this.paymenting = true;
            this.$api.order.wechatpay(id, { type: 'additional_fee' }).then(({ data: response }) => {
              wx.chooseWXPay({
                timestamp: response.timeStamp,
                nonceStr: response.nonceStr,
                package: response.package,
                signType: response.signType,
                paySign: response.paySign,
                success: () => {
                  // res: { errMsg: 'chooseWXPay:ok' }
                  this.$toast.loading({
                    message: this.$t('common.loading'),
                    forbidClick: true,
                    duration: 0,
                    overlay: true,
                  });
                  this.$api.order.wechatpayQuery(id, { type: 'additional_fee' }).then(({ data: resp }) => {
                    this.order = resp.data;
                  }).catch((e) => {
                    showErrorMessage(e);
                  }).finally(() => {
                    this.paymenting = false;
                    this.$toast.clear();
                  });
                },
                cancel: () => {
                  this.paymenting = false;
                },
                fail: () => {
                  this.paymenting = false;
                  // this.$toast.fail(this.$t('payment.payment_fail'));
                },
              });
            }).catch((e) => {
              showErrorMessage(e);
            }).finally(() => {
              this.paymenting = false;
            });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
  #order-detail {
  }
</style>
